import React, { useCallback } from 'react'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id }) => {
  const formProps = {
    name: 'company_form',
    model_name: 'company',
    record_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(record_id), [record_id]),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'contacts',
      'contracts',
      'companyusers',
      'contact',
      'info'
    ],
    initialState: { isReadOnly: !!record_id, },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), [])
  }
  const mergedProps = {
  }
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
