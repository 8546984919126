import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [SUPPORTED_LANGUAGES] = hooks.useSetting('supported_languages')
  const [isReadOnly] = hooks.useFormState('isReadOnly')

  const verifyClientId = useCallback(hooks.useRecordExist('company', 'client_id'), [])

  const mergedProps = {
    isReadOnly,
    SUPPORTED_LANGUAGES,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
