import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import CompanyTab from '@abra/panels/Tab/Company'

import FormSpeedDial from '@abra/elements/speedDial/Form'
import PrimarySection from './Section/Primary'
import CompanyInfoSection from './Section/CompanyInfo'
import ServicesSection from './Section/Services'
import Popups from './Popup'


const CompanyContract = ({ handleClickClientDetail, company }) => {

  const actions = [
    { name: I18n.t('user.label', { count: 1 }), iconName: 'account_circle', onClick: handleClickClientDetail },
  ]

  return (
    <>
      <Popups />
      <Grid container justifyContent='center'>
        <Grid item xs={9}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12} >
              <CompanyTab value={3} />
            </Grid>
            <Grid container item xs={12} >
              <Grid item xs={12}>
                <FormHeader disabledButton goBackPath={`/company/${company}/user`} />
              </Grid>
              <Grid item xs >
                <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
                  <Step>
                    <PrimarySection />
                  </Step>
                  <Step>
                    <CompanyInfoSection />
                  </Step>
                  <Step>
                    <ServicesSection />
                  </Step>
                </GridStepper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormSpeedDial actions={actions} />
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(CompanyContract)
