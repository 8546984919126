import React from 'react'
import { SuspensionPopupForm } from '@abra/elements/Suspensions'

const Dialogs = ({ record_id }) => (
  <>
    <SuspensionPopupForm record_id={record_id} model_name={"companyuser"} />
  </>
)

export default React.memo(Dialogs)
