import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [, { openDialog }] = hooks.useDialogs()
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const [driver, { isLoading }] = hooks.useModel('user', [record_id], { populate: ['vehicle.info', 'vehicle.current_license'], single: true })
  const [orders] = hooks.useModelSearch('order', 'get', {
    initial_filter: { assigned_transport: { driver: record_id }, status: 'attributed' },
    watchers: [record_id]
  })

  const mergedProps = {
    orders,
    openDialog,
    isManagingRecord,
    user_client_id: _.get(driver, 'info.user_client_id'),
    plaque: _.get(driver, 'vehicle.info.plaque'),
    vehicle_id: _.get(driver, 'vehicle.vehicle_id'),
    vehicle_status: _.get(driver, 'vehicle.status'),
    group_id: _.get(driver, 'vehicle.group'),
    license_id: _.get(driver, 'vehicle.current_license.license_id'),
    license_client_id: _.get(driver, 'vehicle.current_license.license_client_id'),
  }

  return (
    <Loader isLoading={!!isLoading} size={30}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
