import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, MenuSelectorField, SingleOptionSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Row1 = ({ isManagingRecord, statusColor, statusChange }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: statusColor, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.email'}
        label={I18n.t('email.label.pro')}
        emptyValue='-'
        required
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('admin', { count: 1 })}
        noneLabel={I18n.t('prefix.no')}
        name={'administration_level'}
        options={[
          { label: I18n.t('company.label', { count: 1 }), value: 'company' },
          { label: I18n.t('contract.label', { count: 1 }), value: 'contract' },
          { label: I18n.t('companyservice.label', { count: 1 }), value: 'service' },
        ]}
      />
    </Grid>
  </Grid >

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: 'subtitle.fontSize', color: 'primary.main' }}>{I18n.t('order.can_book_for')}:</Typography>
    </Grid>
    <Grid container item xs={4} spacing={1}>
      <Grid item xs>
        <SingleOptionSelectorField name={'self_booking'} optionLabel={I18n.t('companyuser.booking.self')} clearOnEmpty={false} />
      </Grid>
      <Grid item xs>
        <SingleOptionSelectorField name={'guest_booking'} optionLabel={I18n.t('companyuser.booking.guest')} clearOnEmpty={false} />
      </Grid>
    </Grid>
    <Grid item xs={4}>
      <MenuSelectorField
        name='booking_level'
        label={I18n.t("companyuser.booking.company")}
        options={[
          { label: I18n.t('company.label', { count: 1 }), value: 'company' },
          { label: I18n.t('contract.label', { count: 1 }), value: 'contract' },
          { label: I18n.t('companyservice.label', { count: 1 }), value: 'service' },
        ]}
      />
    </Grid>
    <Grid item xs={4}>
      <ModelMenuSelectorField
        name='blacklisted_domains'
        label={I18n.t('domain.blacklist', { count: 1 })}
        model_name='domain'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        multiple
        config={{
          forced_filter: { user_type: 'booker' }
        }}
      />
    </Grid>
  </Grid>

const SpecialRuleSection = props => (
  <>
    <StepLabel>
      <Typography variant='h5'> {I18n.t('info.company')}</Typography>
    </StepLabel>
    <StepContent>
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
        <Grid container rowSpacing={2}>
          <Row1 {...props} />
          <Row2 />
        </Grid>
      </Box>
    </StepContent>
  </>
)

export default React.memo(SpecialRuleSection)
