class Callbacks {
  static handleOnAddressResolve(setFormValues) {
    return function addressResolve(details) {
      const address_repo = _.pick(details, [
        'street_number',
        'street',
        'postal_code',
        'city',
        'region',
        'country',
        'coordinates'
      ])
      setFormValues([{ field: 'source_address', value: address_repo }])
    }
  }
}

export default Callbacks
