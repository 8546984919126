import React, { useEffect, useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const isReady = hooks.useFormStatus('isReady')
  const [gsm, user_client_id, first_name, last_name] = hooks.useFieldValues(['auth.gsm', 'info.user_client_id', 'info.first_name', 'info.last_name'])
  const [{ group_id, record_id, isManagingRecord, hasPassword, hasGsm }] = hooks.useFormState()
  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])
  const [user_id] = hooks.useFormState('record_id')

  const handleOrangeCall = useCallback(Callbacks.handleOrangeCallHandler(), [])
  const handleCall = useCallback(Callbacks.handleCallHandler(EXTERNAL_PHONE_SERVICE, handleOrangeCall), [EXTERNAL_PHONE_SERVICE, handleOrangeCall])
  const confirmReset = useCallback(Callbacks.confirmResetHandler(openDialog, user_client_id, user_id), [openDialog, user_client_id, user_id])
  const onResetSmsPassword = useCallback(Callbacks.onResetSmsPasswordHandler(confirmReset), [confirmReset])
  const onResetEmailPassword = useCallback(Callbacks.onResetEmailPasswordHandler(confirmReset), [confirmReset])
  const onCallDriver = useCallback(Callbacks.onCallDriverHandler(openDialog, user_client_id, first_name, last_name), [openDialog, user_client_id, first_name, last_name])

  const onKeyDown = useCallback(Callbacks.onKeyDownHandler(gsm, handleCall), [gsm, handleCall])

  const mergedProps = {
    hasPassword,
    hasGsm,
    isManagingRecord,
    record_id,
    group_id,
    handleCall,
    onResetSmsPassword,
    onResetEmailPassword,
    onCallDriver,
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
