import React, { useCallback } from 'react'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id }) => {
  const formProps = {
    name: 'codepromo_form',
    model_name: 'codepromo',
    record_id,
    related_states: [],
    related_searches: [],
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(), []),
    populate: ['createdBy.info', 'createdBy.customerinfo'],
    initialState: { isReadOnly: !!record_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    modifyChangesOnly: false
  }
  const mergedProps = {}
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
