import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const SpecificParameterSection = ({ companycontract_id }) => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(companycontract_id), [companycontract_id])
  const handleCreate = useCallback(() =>
    window.open(`/pricerule/create?companycontract_id=${companycontract_id}`, '_blank')
    [companycontract_id])
  const handleRowClick = useCallback((rowId, row) => window.open(`/pricerule/${row?.price_rule_id}`), [])
  return (
    <SearchFrame
      model_name={'pricerule'}
      populate={[
        'start_zone',
        'end_zone',
        'pricing',
        'companycontract_pricerules.commercialpackage',
      ]}
      createButtonProps={{
        label: I18n.t('pricerule.add', { count: 1 }),
        onClick: handleCreate
      }}
    >
      <SearchForm
        disableButton
        submitOnMount
        formToFilter={formToFilter}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable tableProps={{
        onRowClick: handleRowClick
      }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  )
}

export default React.memo(SpecificParameterSection)
