class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return filter
    }
  }
  static formToFilterHandler(group) {
    return function formToFilter(values, extra, meta, state) {
      return {
        ...values,
        group
      }
    }
  }
}

export default Callbacks
