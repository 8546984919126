import ScheduleUtils from '@abra/utils/schedule'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {

      }
    }
  }
  static formToFilterHandler(service) {
    return function formToFilter(values, extra, meta, state) {
      return {
        service,
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record,
        schedule: {
          schedule_id: record?.schedule.schedule_id,
          ...ScheduleUtils.loadSchedule(_.get(record, 'schedule')),
          start_time: _.get(_.get(record, 'schedule'), 'start_time'),
          end_time: _.get(_.get(record, 'schedule'), 'end_time')
        }
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        planned_authorized: false,
        immediate_authorized: false,
        priority: 0
      }
    }
  }

  static formToRecordHandler(service) {
    return function formToRecord(values, extra, meta) {
      return {
        priority: _.has(values, 'priority') ? _.get(values, 'priority') : undefined,
        planned_authorized: _.has(values, 'planned_authorized') ? _.get(values, 'planned_authorized') : undefined,
        immediate_authorized: _.has(values, 'immediate_authorized') ? _.get(values, 'immediate_authorized') : undefined,
        schedule: ScheduleUtils.exportSchedule({ ..._.get(values, 'schedule'), type: 'service' }),
        service
      }
    }
  }
}

export default Callbacks
