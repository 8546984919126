import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography } from '@mui/material'

import { AddRowAction } from '@front/squirtle/components/FormTable/Actions'

const ButtonComponent = props => <>
  <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, mt: 2 }}>
    <AddRowAction
      label={<Typography sx={{ fontSize: 15, mt: '1px', fontWeight: 600, ml: 1 }}> {I18n.t('contact.add', { count: 1 })} </Typography>}
      size={'small'}
      variant={'outlined'}
      sx={{ textTransform: 'none' }}
    />
  </Box >
</>

export default React.memo(ButtonComponent)
