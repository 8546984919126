import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'
import { useTheme } from '@mui/styles'

import { Grid } from '@mui/material'
import { ModelAutocompleteField, SingleOptionSelectorField } from '@front/squirtle'

const SearchForm = ({ companycontract_id, ...props }) =>
  <Grid container item xx={12} alignItems='center' justifyContent='flex-start' spacing={3}>
    <Grid item xs={3}>
      <ModelAutocompleteField
        name={'companyservice_id'}
        label={I18n.t('companyservice.name_client_id')}
        model_name={'companyservice'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: q }, { name: q }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { contract: companycontract_id },
          validate_filter: useCallback(() => !!companycontract_id, [companycontract_id]),
          watchers: [companycontract_id],
          mutator: companyservice => ({ label: `${_.get(companyservice, 'client_id')} - ${_.get(companyservice, 'name')}`, ...companyservice })
        }}
        selectSingleOption
        minQueryLength={1}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name={'archive'}
        optionLabel={I18n.t('companyservice.display_archived')}
        getOptionLabelProps={() => ({
          disableTypography: false,
          componentsProps: { typography: { fontSize: 12, color: `${useTheme().palette.text.label} !important` } }
        })}
      />
    </Grid>
  </Grid>

export default React.memo(SearchForm)
