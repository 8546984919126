import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const NoShowSection = ({ companycontract_id, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(companycontract_id), [companycontract_id])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  const handleCreate = useCallback(() =>
    window.open(`/noshow/create?companycontract_id=${companycontract_id}`, '_blank')
    [companycontract_id])
  const handleRowClick = useCallback((rowId, row) => window.open(`/noshow/${_.get(row, 'noshow.noshow_id')}`), [])
  return <>
    <SearchFrame
      model_name={'companycontractnoshow'}
      populate={['noshow', 'commercial_package']}
      createButtonProps={{
        label: I18n.t('noshow.add'),
        onClick: handleCreate
      }}
    >
      <SearchForm
        disableButton
        submitOnMount
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable tableProps={{
        onRowClick: handleRowClick
      }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  </>
}

export default React.memo(NoShowSection)
