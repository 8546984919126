import moment from 'moment'

const loadAuthenticationType = authentication_type => (!!authentication_type
  ? {
    display_list: _.includes(['service'], authentication_type),
    direct_access: _.includes(['direct'], authentication_type)
  } : undefined)

const loadStatus = record => {
  if (record.deleted) return 'archive'
  else if (record.active) return 'active'
  else return 'inactive'
}

const exportStatus = status => (!!status
  ? {
    active: _.includes(['active'], status),
    deleted: _.includes(['archive'], status)
  }
  : undefined)

const exportDate = (dateValue, hours) =>
  !!dateValue ? moment(dateValue, 'YYYY-MM-DD').set(hours).toISOString(true) : undefined

const exportAuthenticationType = (direct_access, display_list) => {
  if (direct_access === undefined && display_list === undefined) return undefined
  if (!direct_access && !display_list) return 'contract'
  else if (!direct_access && !!display_list) return 'service'
  else return 'direct'
}

const Aux = {
  loadAuthenticationType,
  loadStatus,
  exportStatus,
  exportDate,
  exportAuthenticationType
}

export default Aux
