import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('date.label', { count: 1 })} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('operator.label', { count: 1 })} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={`${I18n.t('contract.label', { count: 1 })} / ${I18n.t('companyservice.label', { count: 1 })}`} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('reason.label', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
