import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { ModelAutocompleteField, ModelMenuSelectorField, SingleOptionSelectorComponent } from '@front/squirtle'

import UserUtils from '@front/volcanion/utils/user'


const Row1 = ({ application, resetUser, all_client, setAllClient }) =>
  <Grid container spacing={1}>
    <Grid item xs={3} sm={2} lg={1}>
      <ModelMenuSelectorField
        name={'application'}
        label={I18n.t('application.label', { count: -1 })}
        model_name={'application'}
        searchMode='search'
        labelKeys={['name']}
        loadOnMount
        config={{ sort: ['display_index ASC'] }}
        required
      />
    </Grid>
    <Grid item xs={1}>
      <SingleOptionSelectorComponent
        name='all_client'
        label='Tous les clients'
        value={all_client}
        onChange={setAllClient}
        formLabelProps={{ sx: { fontSize: 13 } }}
        afterChange={resetUser}
      />
    </Grid>
    <Grid item xs={4} sm={3} lg={2} hidden={!!all_client}>
      <ModelAutocompleteField
        name={'user'}
        label={I18n.t('user.label', { count: 1 })}
        model_name={'user'}
        searchMode={'search'}
        searchQuery={'startsWith'}
        config={{
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.customerinfo?.client_number }),
            ...user
          }),
          populate: ['customerinfo', 'info']
        }}
        autocompleteMutator={q => ({
          or: [
            { customerinfo: { client_number: q }, auth: { application } },
            { info: { first_name: q }, auth: { application } },
            { info: { last_name: q }, auth: { application } },
            { customerinfo: { name: q }, auth: { application } }
          ]
        })}
        minQueryLength={1}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid >

const TargetSection = props => <>
  <StepLabel><Typography variant='h5'>{I18n.t('target')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Row1 {...props} />
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(TargetSection)
