import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const requestSubmit = hooks.useGlobalFormFunction('companyuserservices_list', 'requestSubmit')
  const [company] = hooks.useFormState('company')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [isFormListReadOnly] = hooks.useGlobalFormState('companyuserservices_list', 'isReadOnly')

  const mergedProps = {
    isReadOnlyParentForm: isReadOnly,
    isReadOnly: isFormListReadOnly,
    submitSearchTable: requestSubmit,
    company,
    record_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
