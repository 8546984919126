import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField } from '@front/squirtle'

const Row1 = props =>
  <Grid container spacing={2}>
    <Grid item xs={3} sm={2} lg={2}><TextField name='price' label={I18n.t('price.label')} required /></Grid>
    <Grid item xs={4} sm={3} lg={2}><TextField name='currency' label={I18n.t('fiat.label', { count: 1 })} required /></Grid>
  </Grid>

const SettingSection = props => <>
  <StepLabel><Typography variant='h5'>{I18n.t('info.general')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={6}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Row1 {...props} />
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(SettingSection)
