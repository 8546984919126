import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const { DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER } = hooks.useRelayFeatures(['DRIVER_CAN_CALL_CLIENT', 'CLIENT_CAN_CALL_DRIVER'])

  const mergedProps = {
    DRIVER_CAN_CALL_CLIENT,
    CLIENT_CAN_CALL_DRIVER,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
