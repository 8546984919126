import React, { useCallback, useMemo } from 'react'
import { hooks, I18n } from '@front/volcanion/'

import { Typography, Box, StepLabel, StepContent } from '@mui/material'

import { SearchFrame, SearchForm, SearchTable, TableHeader, TableRow, TableRowActions, TableActions, FormContent } from '@front/squirtle'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'
import Callbacks from './callbacks'

const ContactSection = ({ isManagingRecord, ...props }) => {
  const { group_id } = hooks.useParams()
  const [values] = hooks.useGlobalForm('groupcontactinfo_list')

  const has_primary = useMemo(() => {
    const all_paths = _.flatten(_.map(values, (value, key) => _.map(_.keys(value), (k) => [key, k].join('.'))))
    return !!_.find(all_paths, (path) => _.get(values, `${path}.is_primary`))
  }, [values])

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(group_id), [group_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(group_id), [group_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(has_primary), [has_primary])

  return <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('contact.label', { count: 1 })}</Typography>
    </StepLabel>
    {isManagingRecord &&
      <StepContent>
        <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
          <SearchFrame
            model_name='groupcontactinfo'
            allowCreate={false}
            createPlacement='bottom'
            populate={['contactinfo']}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                recordToForm,
                formToRecord
              }}
              tableProps={{
                onRowClick: null,
                enableToolbar: false,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions><ButtonGlobalAction getEmptyForm={getEmptyForm} /></TableActions>
            </SearchTable>
          </SearchFrame>
        </Box>
      </StepContent>}
  </>
}

export default React.memo(ContactSection)
