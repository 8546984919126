import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, StepLabel, StepContent } from '@mui/material'

import List from './List'

const CeilingSection = ({ disabled, record_id, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('ceiling.label', { count: 2 })}</Typography>
  </StepLabel>
  {!disabled &&
    <StepContent><List companycontract_id={record_id} /></StepContent>
  }
</>

export default React.memo(CeilingSection)
