import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const driver_contract_id = _.get(row, 'driver_contract_id')
  const [deleteRecord] = hooks.useModelFunction('drivercontract', 'delete')
  const onDelete = useCallback(() => deleteRecord({ driver_contract_id })
    , [driver_contract_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
