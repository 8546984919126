import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { DateTimeField } from '@front/squirtle'

const Row1 = ({ isManagingRecord, ...props }) =>
  <Grid container spacing={4}>
    <Grid item xs={4}><DateTimeField name='validityStart' label={I18n.t('date.validity.start')} compact required /></Grid>
    <Grid item xs={4}><DateTimeField name='validityEnd' label={I18n.t('date.validity.end')} compact required /></Grid>
  </Grid>

const PeriodSection = props => <>
  <StepLabel><Typography variant='h5'>{I18n.t('date.period')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={12} >
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Row1 {...props} />
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(PeriodSection)
