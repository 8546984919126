import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = props =>
  <Grid item xs={12}>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('user.blacklist')} </Typography>
    </StepLabel >
  </Grid >

const BlackListSection = ({ record_id, ...rest }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])

  return <Grid container rowSpacing={3}>
    <Title />
    <Grid item xs={12}>
      <StepContent>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <SearchFrame
            model_name={'customerblacklist'}
            populate={['operator.info', 'operator.customerinfo']}
            allowCreate={false}
            createPlacement={'bottom'}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              filterToState={filterToState}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                recordToForm,
                formToRecord
              }}
              tableProps={{
                onRowClick: null,
                enableToolbar: false,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions><ButtonGlobalAction /></TableActions>
            </SearchTable>
          </SearchFrame >
        </Box >
      </StepContent>
    </Grid>
  </Grid>
}

export default React.memo(BlackListSection)
