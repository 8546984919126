import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Typography, StepLabel, StepContent } from '@mui/material'
import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = props =>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('companyservice.blacklist')} </Typography>
  </StepLabel>

const BlackListSection = ({ record_id, isManagingRecord }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(record_id), [record_id])

  return <Box hidden={!isManagingRecord}>
    <Title />
    <StepContent>
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <SearchFrame
          model_name={'serviceblacklist'}
          populate={['operator.customerinfo', 'operator.info']}
          allowCreate={false}
          createPlacement={'bottom'}
        >
          <SearchForm
            filterToForm={filterToForm}
            formToFilter={formToFilter}
            filterToState={filterToState}
            submitOnMount
            disableButton
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable
            formProps={{
              recordToForm,
              formToRecord
            }}
            tableProps={{
              onRowClick: _.noop,
              enableToolbar: false,
              enablePagination: false,
              enableRowActions: true,
              enableRowSelection: false,
              enableGlobalActions: true,
              enableGlobalActionsPlacement: 'bottom',
              disableCancelRowLabel: true,
              disableSaveRowLabel: true
            }}
          >
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
            <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
            <TableActions><ButtonGlobalAction /></TableActions>
          </SearchTable>
        </SearchFrame >
      </Box >
    </StepContent>
  </Box>
}

export default React.memo(BlackListSection)
