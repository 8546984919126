import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const customer_blacklist_id = _.get(row, 'customer_blacklist_id')
  const [deleteRecord] = hooks.useModelFunction('customerblacklist', 'delete')
  const onDelete = useCallback(() => deleteRecord({ customer_blacklist_id })
    , [customer_blacklist_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
