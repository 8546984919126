import React, { useEffect, useMemo, useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const { DURATION_DRIVER_TOO_EARLY } = hooks.useRelayConstants(['DURATION_DRIVER_TOO_EARLY'])
  const [enableCustomPayment, setEnableCustomPayment] = hooks.useFormState('enableCustomPayment')
  const [direct_access, payment_types, name, formula_id, delayAtSourceType] = hooks.useFieldValues(['direct_access', 'payment_types', 'name', 'formula', 'delayAtSourceType'])

  const [company_id] = hooks.useFormState('company_id')
  const [company] = hooks.useModel('company', [company_id], { single: true })
  const [formula] = hooks.useModel('commercialFormula', [formula_id], { single: true })
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const getTitleFormatted = useCallback((cmpy_name, cntr_name, isManaging) => !!isManaging && `${cmpy_name} - ${cntr_name}`, [])
  const titleFormatted = useMemo(() => getTitleFormatted(_.get(company, 'name'), name, isManagingRecord), [_.get(company, 'name'), name, isManagingRecord])

  const has_delay = _.isFinite(formula?.delay_at_source)
  const default_delay_label = _.compact([I18n.t('driver.waiting_default_delay'), _.isFinite(DURATION_DRIVER_TOO_EARLY) ? `(${DURATION_DRIVER_TOO_EARLY}min)` : `(${I18n.t('undefined')})`]).join(' ')
  const formula_delay_label = _.compact([formula?.name, `(${formula?.delay_at_source}min)`]).join(' ')

  const delayAtSourceOptions = useMemo(() => ([
    { label: !!has_delay ? formula_delay_label : default_delay_label, value: 'default' },
    { label: I18n.t('driver.waiting_custom_delay'), value: 'custom' },
  ]), [has_delay, default_delay_label, formula_delay_label])

  const verifyClientId = useCallback(hooks.useRecordExist('companycontract', 'client_id'), [])

  const handleClickEnableCustomPayment = useCallback(() => {
    setEnableCustomPayment(!enableCustomPayment)
  }, [enableCustomPayment, setEnableCustomPayment])

  const onAddressResolve = useCallback(Callbacks.handleOnAddressResolve(setFormValues), [setFormValues])

  useEffect(() => {
    _.isEmpty(payment_types) && !!enableCustomPayment && setEnableCustomPayment(false)
  }, [_.join(payment_types, ',')])

  const handleDirectAccessChange = useCallback(value => {
    !!value && setFormValues([{ field: 'display_list', value: false }])
  }, [])
  const handleDisplayListChange = useCallback(value => {
    !!value && setFormValues([{ field: 'direct_access', value: false }])
  }, [])
  const onDelayAtSourceTypeChange = useCallback((value) => {
    setFormValues([{ field: 'delay_at_source', value: value === 'custom' ? 0 : null }])
  }, [])

  const mergedProps = {
    enableCustomPayment,
    payment_types,
    isManagingRecord,
    isReadOnly,
    direct_access,
    titleFormatted,
    delayAtSourceOptions,
    delayAtSourceType,
    onDelayAtSourceTypeChange,
    handleDirectAccessChange,
    handleDisplayListChange,
    handleClickEnableCustomPayment,
    onAddressResolve,
    verifyClientId,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
