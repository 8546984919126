
import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box } from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'

import { TextField, SingleOptionSelectorComponent, DateTimeField } from '@front/squirtle/'

const Row1 = ({ handleChangeMandatoryPassword, isReadOnly, hasPassword }) =>
  <Grid container item xs={12} spacing={1} alignItems='flex-end'>
    <Grid item xs>
      <SingleOptionSelectorComponent
        value={hasPassword}
        onChange={handleChangeMandatoryPassword}
        disabled={isReadOnly}
        label={I18n.t('password.mandatory.label', { count: 1 })}
        clearOnEmpty={false}
        formGroupProps={{ sx: { pt: '19px' } }}
      />
    </Grid>
    <Grid item xs={5}>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            name={'password'}
            label={I18n.t('password.current.label', { count: 1 })}
            required={!!hasPassword}
            inputProps={{ maxLength: 4 }}
            normalize={v => _.toUpper(_.replace(v, /[^0-9a-z]/gi, ''))}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name={'new_password'}
            label={I18n.t('password.new_short')}
            inputProps={{ maxLength: 4 }}
            normalize={v => _.toUpper(_.replace(v, /[^0-9a-z]/gi, ''))}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs>
      <DateTimeField
        name={'new_password_availableSince'}
        dateProps={{
          label: I18n.t('date.label', { count: 1 }),
        }}
        timeProps={{
          label: I18n.t('hour.label', { count: 1 })
        }}
        disablePast
        disabled={!!isReadOnly || !hasPassword}
      />
    </Grid>
  </Grid>

const Payment = props =>
  <Grid container spacing={1} rowSpacing={2}>
    <Grid item xs={12}>
      <Box display='flex' alignItems='center'>
        <Box color='primary.main'> <SecurityIcon /> </Box>
        <Typography sx={{ ml: 1, fontSize: 'subtitle.fontSize', color: 'primary.main' }}> {I18n.t('password.label', { count: 1 })}: </Typography>
      </Box>
    </Grid>
    <Row1 {...props} />
  </Grid>


export default React.memo(Payment)
