import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [values, setFormValues] = hooks.useForm()

  const name = hooks.useSectionPrefix()

  const afterUserChange = useCallback((new_value) => {
    const other_paths = _.difference(_.flatten(_.map(values, (value, key) => _.map(_.keys(value), (k) => [key, k].join('.')))), [name])
    if (!!new_value) {
      setFormValues(_.map(other_paths, (other_path) => ({ field: [other_path, 'is_primary'].join('.'), value: false })))
    }
    else {
      const other_true = _.find(other_paths, (other_path) => _.get(values, `${other_path}.is_primary`) && name != other_path
      )
      if (!other_true && !_.isEmpty(other_paths))
        setFormValues(_.map(_.take(other_paths, 1), (other_path) => ({ field: [other_path, 'is_primary'].join('.'), value: true })))
      else if (_.isEmpty(other_paths))
        setFormValues([{ field: [name, 'is_primary'].join('.'), value: true }])
    }
  }, [name, values, setFormValues])

  const mergedProps = {
    afterUserChange
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
