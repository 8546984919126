import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { handleCall } = props
  const [user_auth_id] = hooks.useFormState('user_auth_id')
  const { openNotification } = hooks.useNotification()
  const [handleResetPasswordAction] = hooks.useModelFunction('stage', 'handleResetPasswordAction')
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const [gsm] = hooks.useFieldValues(['auth.gsm'])

  const resetPassword = useCallback(Callbacks.resetPasswordHandler(openNotification, handleResetPasswordAction), [openNotification, handleResetPasswordAction])
  const onCallDriver = useCallback(Callbacks.onCallDriverHandler(handleCall, gsm, openNotification), [handleCall, gsm, openNotification])
  const handleSubmit = useCallback(() => requestSubmit({ forceSubmit: true }), [requestSubmit])

  const mergedProps = {
    user_auth_id,
    resetPassword,
    onCallDriver,
    handleSubmit,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
