import React, { useCallback } from 'react'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { IconButton, Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import { Save } from '@mui/icons-material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = ({ submitSearchTable, isReadOnlyParentForm, isReadOnly, ...props }) =>
  <Grid container item xs={12} spacing={1} alignItems='center'>
    <Grid item xs={11}>
      <Typography variant={'h5'}> Services associées </Typography>
    </Grid>
    <Grid item xs={1} hidden={!isReadOnlyParentForm || !!isReadOnly}>
      <IconButton onClick={submitSearchTable} sx={{ p: 0, color: 'primary.main' }}> <Save /> </IconButton>
    </Grid>
  </Grid >

const ServiceSection = ({ record_id, company, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])

  return <>
    <StepLabel>
      <Title {...props} />
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
        <SearchFrame
          model_name={'companyuserservices'}
          populate={['companyuser', 'companyservice.contract']}
          allowCreate={false}
          createPlacement={'bottom'}
        >
          <SearchForm
            filterToForm={filterToForm}
            formToFilter={formToFilter}
            filterToState={filterToState}
            submitOnMount={!!record_id}
            submitOnEnter={false}
            disableButton
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable
            formProps={{
              recordToForm,
              formToRecord
            }}
            tableProps={{
              onRowClick: null,
              enableToolbar: false,
              enablePagination: false,
              enableRowActions: true,
              enableRowSelection: false,
              enableGlobalActions: true,
              enableGlobalActionsPlacement: 'bottom',
              disableCancelRowLabel: true,
              disableSaveRowLabel: true
            }}
          >
            <TableHeader><Header /></TableHeader>
            <TableRow ><Row company={company} /></TableRow>
            <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
            <TableActions><ButtonGlobalAction /></TableActions>
          </SearchTable>
        </SearchFrame >
      </Box>
    </StepContent>
  </>
}

export default React.memo(ServiceSection)
