import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.label', { count: 1 })} icon={'calendar_today'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('begin')} icon={'access_time'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('end')} icon={'access_time'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('priority.label', { count: 1 })} icon={'priority_high'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('order.immediate.short')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('order.planned.short')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('day.label', { count: -1 })} />
  </TableCell>
</>

export default React.memo(Header)
