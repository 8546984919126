import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { SingleOptionSelectorField } from '@front/squirtle'

const Row1 = () =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'heatmap_blacklist.available'}
        label={I18n.t('heatmap.available')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'heatmap_blacklist.heat'}
        label={I18n.t('heatmap.heat')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'heatmap_blacklist.dispatch'}
        label={I18n.t('heatmap.dispatch')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'heatmap_blacklist.planned'}
        label={I18n.t('heatmap.planned')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>


const HeatmapBlacklistSection = (props) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('heatmap.exclude', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(HeatmapBlacklistSection)
