import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const CeilingSection = ({ companycontract_id, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(companycontract_id), [companycontract_id])

  const handleCreate = useCallback(() =>
    window.open(`/ceiling/create?companycontract_id=${companycontract_id}`, '_blank')
    [companycontract_id])

  const handleRowClick = useCallback((rowId, row) => window.open(`/ceiling/${_.get(row, 'ceiling.ceiling_id')}`), [])

  return (
    <SearchFrame
      model_name={'companycontractceiling'}
      populate={['ceiling', 'commercial_package']}
      createButtonProps={{
        label: I18n.t('ceiling.add', { count: 1 }),
        onClick: handleCreate
      }}
    >
      <SearchForm
        submitOnMount
        disableButton
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable tableProps={{
        onRowClick: handleRowClick
      }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  )
}

export default React.memo(CeilingSection)
