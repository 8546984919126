import React from 'react'
import { Box } from '@mui/material'
import { TextField } from '@front/squirtle'

const Form = props =>
  <Box hidden>
    <TextField name='test' />
  </Box>

export default React.memo(Form)
