import { hooks } from '@front/volcanion'
import React from 'react'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const mergedProps = {
    isManagingRecord
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
