import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [defaultCountry, , country_state] = hooks.useSetting('country_code')
  const verifyGsm = useCallback(hooks.useRecordExist('user', 'auth.gsm', { driverinfo: { '!': null } }), [])

  const mergedProps = {
    defaultCountry,
    verifyGsm,
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([country_state])} size={30}>
      <Component {...mergedProps} />
    </Loader>
  )
}
export default withContainer
