import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, StepLabel, StepContent } from '@mui/material'

import List from './List'

const ServiceSection = ({ disabled, record_id, ...props }) => <>
  <StepLabel>
    <Typography fontSize='h6.fontSize' color='text.title'>{I18n.t('companyservice.label', { count: 2 })}</Typography>
  </StepLabel>
  {!disabled &&
    <StepContent >
      <List companycontract_id={record_id} />
    </StepContent>
  }
</>

export default React.memo(ServiceSection)
