import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialpackage.label', { count: 1 })} icon={'local_taxi'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('pricerule.label', { count: 1 })} icon={'rule'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('zone.begin', { count: 1 })} icon={'flag'} IconProps={{ color: 'green' }} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('zone.end', { count: 1 })} icon={'flag'} IconProps={{ color: 'pink' }} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('ceiling.max_customer')} icon={'euro_symbol'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('ceiling.min_customer')} icon={'euro_symbol'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('ceiling.max_driver')} icon={'euro_symbol'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('ceiling.min_driver')} icon={'euro_symbol'} />
  </TableCell>
</>

export default React.memo(Header)
