import React from 'react'
import { hooks } from '@front/volcanion'

const getEventOptions = (record_id, name) => ({
  initial_filter: {},
  forced_filter: {
    name,
    driver: record_id
  },
  limit: 1,
  sort: ['producedAt DESC'],
  mutator: 'producedAt',
  validate_filter: () => !!record_id,
  watchers: [record_id]
})

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')

  const [[last_unpairing]] = hooks.useModelSearch('event', 'get', getEventOptions(record_id, 'unpairing'))
  const [[last_pairing]] = hooks.useModelSearch('event', 'get', getEventOptions(record_id, 'pairing'))
  const [[last_online]] = hooks.useModelSearch('event', 'get', getEventOptions(record_id, 'driver_online'))
  const [[last_offline]] = hooks.useModelSearch('event', 'get', getEventOptions(record_id, 'driver_offline'))
  const [[last_refreshtoken]] = hooks.useModelSearch('event', 'get', getEventOptions(record_id, 'refresh_token_removed'))

  const mergedProps = {
    last_refreshtoken,
    last_unpairing,
    last_pairing,
    last_online,
    last_offline
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
