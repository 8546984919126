import React from 'react'
import { hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { DateField, TextField, ModelAutocompleteField } from '@front/squirtle'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)
  return <>
    <TableCell size={'small'}>
      <DateField
        name={'createdAt'}
        inputFormat={'DD/MM/YYYY HH:mm'}
        mask={'__/__/____ __:__'}
        disabled
        renderInputProps={{
          inputProps: { style: { textAlign: 'center' } }
        }}
        disableOpenPicker
      />
    </TableCell>
    <TableCell size={'small'}> <TextCell value={operator_name} /></TableCell>
    <TableCell size={'small'} sx={{ width: 1 / 3 }}>
      <ModelAutocompleteField
        name={'service'}
        model_name='companyservice'
        config={{
          populate: ['contract'],
          mutator: elem => ({ label: `${_.get(elem, 'contract.client_id')} - ${_.get(elem, 'client_id')}`, ...elem })
        }}
        autocompleteMutator={q => ({
          or: [
            { client_id: q },
            { contract: { client_id: q } }
          ]
        })}
        multiple={false}
        searchQuery={'='}
        searchMode='search'
        allowNone={false}
        loadOnFocus
        loadOnMount
        renderInputProps={{
          inputProps: { style: { textAlign: 'center' } }
        }}
      />
    </TableCell>
    <TableCell size={'small'}>
      <TextField
        name={'reason'}
        inputProps={{ style: { textAlign: 'center' } }}
      />
    </TableCell>
  </>
}

export default React.memo(Row)
