import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, StepLabel, StepContent, Box } from '@mui/material'
import { TextField } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'

const FinnancialSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.finnancial')} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1, }}>
      <Grid container rowSpacing={2}>
        <Grid item xs>
          <TextField
            name={'bank_IBAN'}
            label={I18n.t('bank.iban')}
            required
            validate={isRequired}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name={'bank_BIC'}
            label={I18n.t('bank.bic')}
            required
            validate={isRequired}
          />
        </Grid>
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(FinnancialSection)
