import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { MenuSelectorField, ModelAutocompleteField } from '@front/squirtle'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const [group] = hooks.useModel('group', [_.get(row, 'group.group_id')], { single: true })
  return <>
    <TableCell size={'small'}>
      <ModelAutocompleteField
        name={'group.group_id'}
        model_name={'group'}
        autocompleteKey={'group_client_id'}
        labelKeys={['group_client_id']}
        searchQuery={'='}
        searchMode='search'
        selectSingleOption
        clearOnEmpty
        loadOnMount
      />
    </TableCell>
    <TableCell size={'small'}><TextCell value={_.get(group, 'company_type')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(group, 'name')} /></TableCell>
    <TableCell size={'small'} sx={{ width: 1 / 3 }}>
      <MenuSelectorField
        name={'authorization_type'}
        options={_.compact([
          { label: I18n.t('vehicle.group'), value: 'group' },
          !!_.get(group, 'family') && { label: I18n.t('vehicle.family'), value: 'family' }
        ])}
        selectSingleOption
        selectFirst
      />
    </TableCell>
  </>
}

export default React.memo(Row)
