import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [user_auth_id] = hooks.useFormState('user_auth_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const [, setSuspendedState] = hooks.useFormState('suspended')
  const verifyClientId = useCallback(hooks.useRecordExist('user', 'info.user_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])

  const [user] = hooks.useModel('user', [record_id], { single: true, populate: ['auth', 'info'] })
  const [{ hasPhoto, photoURL }, { getHasPhoto, uploadPhoto, deletePhoto }, { hasPhotoState }] = hooks.useHandlePhoto(record_id)

  const onConfirmDeletePhoto = useCallback(Callbacks.onConfirmDeletePhotoHandler(openDialog), [openDialog])
  const onDeletePhoto = useCallback(Callbacks.onDeletePhotoHandler(deletePhoto, getHasPhoto), [deletePhoto, getHasPhoto])

  const onUploadPhoto = useCallback(Callbacks.onUploadPhotoHandler(uploadPhoto, getHasPhoto), [uploadPhoto, getHasPhoto])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])
  const driverCDN = useMemo(() => _.upperCase(user?.info?.gender) === 'F' ? 'female_driver' : 'driver', [user?.info?.gender])


  const mergedProps = {
    user_auth_id,
    isManagingRecord,
    isReadOnly,
    statusColor,
    statusChange,
    verifyClientId,
    hasPhoto,
    photoURL,
    driverCDN,
    uploadPhoto,
    onConfirmDeletePhoto,
    onDeletePhoto,
    hasPhotoState,
    onUploadPhoto
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
