import React from 'react'

import { Grid, Chip, TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Row = ({ row }) => {
  const commercialpackages = _.map(row?.companycontract_pricerules, 'commercialpackage')
  return <>
    <TableCell size={'small'}>
      <Grid container spacing={1} justifyContent="center" >
        {_.map(commercialpackages, cp => {
          return <Grid item xs>
            <Chip
              key={cp?.commercial_package_id}
              label={CPUtils.getTranslatedLabel(cp)?.label}
              size="small"
            />
          </Grid>
        })}
      </Grid>
    </TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'start_zone.name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'end_zone.name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'pricing.flat_rate')} /></TableCell>
  </>;
}

export default React.memo(Row)
