import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'
import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [, setSuspendedState] = hooks.useFormState('suspended')
  const [status] = hooks.useFieldValues(['status'])

  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    isManagingRecord,
    statusColor,
    statusChange,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
