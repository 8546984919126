import React, { useState, useCallback, useEffect } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [isReadOnly] = hooks.useFormState(['isReadOnly'])
  const password = hooks.useFieldValue('password')
  const [hasPassword, setHasPassword] = useState()

  const handleChangeMandatoryPassword = useCallback(value => {
    setHasPassword(value)
    if (!!value) return
    setFormValues([
      { field: 'password', value: null },
      { field: 'new_password', value: null },
      { field: 'password_available_date', value: null }
    ])
  }, [setHasPassword, setFormValues])

  useEffect(() => {
    setHasPassword(!!password)
  }, [])

  const mergedProps = {
    handleChangeMandatoryPassword,
    hasPassword,
    isReadOnly
  }

  return <Component {...mergedProps} />
}

export default withContainer
