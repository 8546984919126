import React from 'react'
import { I18n } from '@front/volcanion'
import { TimeUtils } from '@front/volcanion/utils'

import { Grid, Box, StepContent } from '@mui/material'

import { TextComponent } from '@front/squirtle'


const Rows = ({ last_online, last_offline, last_pairing, last_unpairing, last_refreshtoken }) =>
  <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1, mt: 3 }}>
    <Grid container spacing={2}>
      <Grid item xs>
        <TextComponent disabled value={TimeUtils.formatDateBackToFront(last_pairing, 'DD/MM/YYYY HH:mm', '-')} label={I18n.t('event.last.pairing')} />
      </Grid>
      <Grid item xs>
        <TextComponent disabled value={TimeUtils.formatDateBackToFront(last_unpairing, 'DD/MM/YYYY HH:mm', '-')} label={I18n.t('event.last.unpairing')} />
      </Grid>
      <Grid item xs>
        <TextComponent disabled value={TimeUtils.formatDateBackToFront(last_online, 'DD/MM/YYYY HH:mm', '-')} label={I18n.t('event.last.online')} />
      </Grid>
      <Grid item xs>
        <TextComponent disabled value={TimeUtils.formatDateBackToFront(last_offline, 'DD/MM/YYYY HH:mm', '-')} label={I18n.t('event.last.offline')} />
      </Grid>
      <Grid item xs>
        <TextComponent disabled value={TimeUtils.formatDateBackToFront(last_refreshtoken, 'DD/MM/YYYY HH:mm', '-')} label={I18n.t('event.last.logout')} />
      </Grid>
    </Grid>
  </Box>


const ConnectionSection = props =>
  <StepContent >
    <Rows {...props} />
  </StepContent>

export default React.memo(ConnectionSection)
