class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'archived'],
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
        contract
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter({ archive, ...values }, extra, meta, state) {
      const status = !!archive ? { active: 0, deleted: 1 } : { deleted: 0 }
      return {
        ...status,
        contract: _.get(state, 'contract'),
        ...values
      }
    }
  }
}

export default Callbacks
