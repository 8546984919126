class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return filter
    }
  }
  static filterToStateHandler(company_contract) {
    return function filterToState(state) {
      return { company_contract, ...state }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return {
        company_contract: _.get(state, 'company_contract'),
        ...values
      }
    }
  }
}

export default Callbacks
