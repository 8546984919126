import React from 'react'

import { ModelMenuSelectorField } from '@front/squirtle'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'commercialpackage.name_translated')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'specific.name')} /></TableCell>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name={'mode'}
      model_name='ridemode'
      labelKeys={['name_translated']}
      noneLabel={I18n.t('all')}
    />
  </TableCell>
</>

export default React.memo(Row)
