import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'
import { Loader } from '@front/squirtle'
import { HookUtils } from '@front/volcanion/utils'

const withForm = Component => props => {
  const { companyservice_id } = hooks.useParams()
  const search_params = hooks.useSearchParams()
  const companycontract_id = _.head(_.get(search_params, 'companycontract_id'))
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })


  const [commentcategory, , commentcategory_status] = hooks.useModelSearch('commentcategory', 'get', { initial_filter: {} })
  const [commentTypes, , commentTypes_status] = hooks.useModelSearch('commenttype', 'get', { initial_filter: {} })
  const commentB2BCategorie = _.get(_.find(commentcategory, ['client_id', 'b2b']), 'commentcategory_id')
  const [user, user_status] = hooks.useActiveUser()
  const auth_account = _.get(companycontract, 'auth_account')
  const isLoading = HookUtils.getLoadingState([commentcategory_status, commentTypes_status, user_status])
  const formProps = {
    name: 'companyservice_form',
    model_name: 'companyservice',
    record_id: companyservice_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(auth_account), [auth_account]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(
      Callbacks.formToRecordHandler(companycontract_id, commentB2BCategorie, commentTypes, user)
      , [companycontract_id, commentB2BCategorie, commentTypes, user]),
    populate: [
      'driver_options',
      'vehicle_options',
      'customerref_option.refs',
      'companyservice_schedules.schedule',
      'comments.type',
      'contact',
      'contract'
    ],
    initialState: { isReadOnly: !!companyservice_id, auth_account, companycontract_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }
  const mergedProps = {
  }
  return (
    <Loader isLoading={isLoading}>
      <ModelForm {...formProps}>
        <Component {...mergedProps} />
      </ModelForm>
    </Loader>
  )
}

export default withForm
