import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField } from '@front/squirtle'

const Row1 = props =>
  <Grid container spacing={1}>
    <Grid item xs={3} sm={2} lg={1}>
      <TextField
        name='amount'
        label={I18n.t('quotation.amount', { count: 1 })}
        required
        _adornment={{ sx: { mb: '3px' } }}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
      />
    </Grid>
    <Grid item xs={4} sm={3} lg={2}>
      <TextField
        name='max_global_usage'
        label={I18n.t('usage.max')}
        required
        InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
      />
    </Grid>
    <Grid item xs={4} sm={3} lg={2}>
      <TextField
        name='max_user_usage'
        label={I18n.t('usage.client')}
        required
        InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
      />
    </Grid>
  </Grid>

const SettingSection = props => <>
  <StepLabel><Typography variant='h5'>{I18n.t('info.general')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Row1 {...props} />
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(SettingSection)
