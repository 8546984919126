import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [application] = hooks.useFieldValues(['application'])
  const [all_client, setAllClient] = hooks.useFormState('all_client')
  const setFieldValues = hooks.useFormChange()

  const resetUser = useCallback(value => !!value && setFieldValues([{ field: 'user', value: undefined }]), [setFieldValues])

  const mergedProps = {
    application,
    all_client,
    setAllClient,
    resetUser
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
