import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = props =>
  <Grid container item xs={12}>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('group.label', { count: 1 })} </Typography>
    </StepLabel >
  </Grid>

const AdherentsSection = ({ record_id }) => {
  const { openNotification } = hooks.useNotification()

  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const onRowClick = useCallback((rowId, row) => window.open(`/adherent/${_.get(row, 'group.group_id')}`), [])
  const onSubmitFailed = useCallback(Callbacks.onSubmitFailedHandler(openNotification), [openNotification])
  return <>
    <Grid container rowSpacing={3}>
      <Title />
      <Grid item xs={12}>
        <StepContent>
          <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
            <SearchFrame
              model_name={'drivercontract'}
              populate={['group']}
              allowCreate={false}
              createPlacement={'bottom'}
              watch_type={'index'}
              sort={['createdAt DESC']}
            >
              <SearchForm
                formToFilter={formToFilter}
                submitOnMount={!!record_id}
                disableButton
              >
                <FormContent><Form /></FormContent>
              </SearchForm>
              <SearchTable
                formProps={{
                  recordToForm,
                  formToRecord,
                  onSubmitFailed
                }}
                tableProps={{
                  onRowClick,
                  enableToolbar: false,
                  enablePagination: false,
                  enableRowActions: true,
                  enableRowSelection: false,
                  enableGlobalActions: true,
                  enableGlobalActionsPlacement: 'bottom',
                  disableCancelRowLabel: true,
                  disableSaveRowLabel: true
                }}
              >
                <TableHeader><Header /></TableHeader>
                <TableRow><Row /></TableRow>
                <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
                <TableActions><ButtonGlobalAction /></TableActions>
              </SearchTable>
            </SearchFrame >
          </Box >
        </StepContent>
      </Grid>
    </Grid>
  </>
}

export default React.memo(AdherentsSection)
