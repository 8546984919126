import Aux from './Aux'
import moment from 'moment'
import GeoUtils from '@front/volcanion/utils/geo'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        client_id: _.get(record, 'client_id'),
        name: _.get(record, 'name'),
        auth_account: _.get(record, 'auth_account'),
        availableSince: _.get(record, 'availableSince'),
        availableUntil: _.get(record, 'availableUntil'),
        comment: _.get(record, 'comment'),
        ivr_access: _.get(record, 'ivr_access'),
        invoice_type: _.get(record, 'invoice_type'),
        contact: _.get(record, 'contact'),
        formula: _.get(record, 'formula'),
        allow_auto_subscription: _.get(record, 'allow_auto_subscription'),
        payment_types: !_.isEmpty(_.get(record, 'payment_types')) ? _.map(_.get(record, 'payment_types'), 'paymenttype') : undefined,
        status: Aux.loadStatus(record),
        commpanycontract_pricerule: _.get(record, 'commpanycontract_pricerule'),
        ...Aux.loadAuthenticationType(_.get(record, 'authentication_type')),
        address: {
          formatted_address: GeoUtils.mergeAddress(record?.source_address)
        },
        source_address: record?.source_address,
        exclude_heatmap: record?.exclude_heatmap,
        delayAtSourceType: _.isNull(record?.delay_at_source) ? 'default' : 'custom',
        delay_at_source: record?.delay_at_source
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active',
        invoice_type: 'do',
        availableSince: moment.utc().format(),
        formula: null,
        direct_access: false,
        display_list: false,
        auth_account: false,
        allow_auto_subscription: false,
        delayAtSourceType: 'default'
      }
    }
  }

  static formToRecordHandler(company, operator, companycontract) {
    return function formToRecord(values, extra, meta, state) {
      return {
        company,
        client_id: _.get(values, 'client_id'),
        name: _.get(values, 'name'),
        auth_account: _.get(values, 'auth_account'),
        availableSince: Aux.exportDate(_.get(values, 'availableSince'), { hour: 0, minute: 1, second: 0 }),
        availableUntil: Aux.exportDate(_.get(values, 'availableUntil'), { hour: 23, minute: 59, second: 59 }),
        comment: _.has(values, 'comment') ? _.merge({}, _.get(values, 'comment', { type: 'MVOTypbow', report: operator, category: 'JFPNk9Qxj' })) : undefined,
        ivr_access: _.get(values, 'ivr_access'),
        invoice_type: _.get(values, 'invoice_type'),
        contact: _.get(values, 'contact'),
        allow_auto_subscription: _.get(values, 'allow_auto_subscription'),
        payment_types: _.has(values, 'payment_types') ? _.map(_.get(values, 'payment_types'), elem => ({
          paymenttype: elem,
          companycontract
        })) : undefined,
        formula: _.get(values, 'formula'),
        commpanycontract_pricerule: _.get(values, 'commpanycontract_pricerule'),
        authentication_type: Aux.exportAuthenticationType(_.get(values, 'direct_access'), _.get(values, 'display_list')),
        ...Aux.exportStatus(_.get(values, 'status')),
        source_address: values?.source_address,
        exclude_heatmap: values?.exclude_heatmap,
        delay_at_source: values?.delayAtSourceType === 'custom' ? values?.delay_at_source : null
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        record_id: _.get(record, 'companycontract_id'),
        title: _.get(record, 'client_id'),
        enableCustomPayment: !_.isEmpty(_.get(record, 'payment_types')),
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
