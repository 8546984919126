import React from 'react'
import { FormTable, TableHeader, TableRow } from '@front/squirtle'

import Header from './Header'
import Row from './Row'

const ActiveJobs = ({ job_ids }) =>
  <FormTable
    model_name={'job'}
    loadOnMount={false}
    config={{
      populate: [
        'first_step.order.do.commercial_formula',
        'first_step.order.do.customerinfo',
        'first_step.order.service.contract.company',
        'first_step.order.service.contract.formula',
      ],
      initial_filter: {},
      forced_filter: {
        job_id: job_ids,
      },
    }}
    tableProps={{
      enablePagination: false,
      onRowClick: null,
    }}
  >
    <TableHeader><Header /></TableHeader>
    <TableRow><Row /></TableRow>
  </FormTable>

export default ActiveJobs
