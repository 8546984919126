import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextHeader, SortHeader } from '@abra/elements/table/headers'

const Header = ({ paginationControls, paginationState }) => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialpackage.label', { count: 1 })} icon={'local_taxi'} />
  </TableCell>
  <TableCell align='center'>
    <SortHeader
      label={I18n.t('pricerule.label', { count: 1 })}
      icon={'gavel'}
      sortField='name'
      handleSort={paginationControls?.changeSort}
      sortState={paginationState?.sort}
    />
  </TableCell>
  <TableCell align='center'>
    <SortHeader
      label={I18n.t('zone.begin', { count: 1 })}
      icon={'flag'}
      IconProps={{ color: 'green' }}
      sortField='start_zone.name'
      handleSort={paginationControls?.changeSort}
      sortState={paginationState?.sort}
    />
  </TableCell>
  <TableCell align='center'>
    <SortHeader
      label={I18n.t('zone.end', { count: 1 })}
      icon={'flag'}
      IconProps={{ color: 'pink' }}
      sortField='end_zone.name'
      handleSort={paginationControls?.changeSort}
      sortState={paginationState?.sort}
    />
  </TableCell>
  <TableCell align='center'>
    <SortHeader
      label={I18n.t('quotation.base')}
      icon={'euro_symbol'}
      sortField='pricing.flat_rate'
      handleSort={paginationControls?.changeSort}
      sortState={paginationState?.sort}
    />
  </TableCell>
</>

export default React.memo(Header)
