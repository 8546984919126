import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, StepLabel, StepContent } from '@mui/material'

import List from './List'

const HistorySection = ({ isManagingRecord, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('history.recent')}</Typography>
  </StepLabel>
  {!!isManagingRecord &&
    <StepContent >
      <List {...props} />
    </StepContent>
  }
</>

export default React.memo(HistorySection)
