import React from 'react'
import { I18n } from '@front/volcanion'

import { ConfirmationDialog, DialogContent } from '@front/squirtle'
import { SuspensionPopupForm } from '@abra/elements/Suspensions'
import ActiveJobs from './ActiveJobs'

const Dialogs = ({ resetPassword, user_auth_id, onCallDriver, handleSubmit }) => (
  <>
    <SuspensionPopupForm record_id={user_auth_id} />
    <ConfirmationDialog name={'confirm_reset_password'} onConfirm={resetPassword} />
    <ConfirmationDialog
      name={'confirm_call_driver'}
      onConfirm={onCallDriver}
      yesLabel={I18n.t('action.confirm')}
      noLabel={I18n.t('action.cancel')}
    />
    <ConfirmationDialog
      name={'show_active_jobs'}
      onConfirm={handleSubmit}
      yesLabel={I18n.t('action.confirm')}
      noLabel={I18n.t('action.cancel')}
    >
      <DialogContent>
        <ActiveJobs />
      </DialogContent>
    </ConfirmationDialog>
  </>
)

export default React.memo(Dialogs)
