class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name'),
        user: _.get(record, 'user'),
        max_global_usage: _.get(record, 'max_global_usage'),
        max_user_usage: _.get(record, 'max_user_usage'),
        validityStart: _.get(record, 'validityStart'),
        validityEnd: _.get(record, 'validityEnd'),
        amount: _.get(record, 'amount'),
        application: _.get(record, 'application'),
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        name: _.get(values, 'name'),
        user: _.get(values, 'user'),
        max_global_usage: _.get(values, 'max_global_usage'),
        max_user_usage: _.get(values, 'max_user_usage'),
        validityStart: _.get(values, 'validityStart'),
        validityEnd: _.get(values, 'validityEnd'),
        amount: _.get(values, 'amount'),
        application: _.get(values, 'application')
      }
    }
  }

  static getEmptyStateHandler() {
    return function getEmptyState() {
      return {
        all_client: true
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        createdAt: _.get(record, 'createdAt'),
        all_client: !_.get(record, 'user')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
