import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion/'

import { TableCell } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

const Row = ({ row, isSelected, company, ...props }) => {
  const [, setSectionValues] = hooks.useSection(hooks.useSectionPath())
  const onContractChange = useCallback(() => {
    setSectionValues([{ field: 'service', value: null }])
  }, [setSectionValues])

  return <>
    <TableCell size={'small'}>
      <ModelMenuSelectorField
        name={'contract'}
        model_name='companycontract'
        labelKeys={['name']}
        searchMode='search'
        allowNone={false}
        disabled
      />
    </TableCell>
    <TableCell size={'small'}>
      <ModelMenuSelectorField
        name={'contract'}
        model_name='companycontract'
        labelKeys={['client_id']}
        searchMode='search'
        allowNone={false}
        config={{
          forced_filter: { company, companyservices: { '!': null }, active: 1 },
          validate_filter: useCallback(() => !!company, [company]),
          watchers: [company],
          default_limit: 100,
        }}
        loadOnFocus
        afterChange={onContractChange}
      />
    </TableCell>
    <TableCell size={'small'}>
      <ModelMenuSelectorField
        name={'service'}
        model_name='companyservice'
        labelKeys={['name']}
        searchMode='search'
        allowNone={false}
        disabled
        loadOnFocus
      />
    </TableCell>
    <TableCell size={'small'}>
      <ModelMenuSelectorField
        name={'service'}
        model_name='companyservice'
        labelKeys={['client_id']}
        searchMode='search'
        allowNone={false}
        config={{
          forced_filter: { contract: row?.contract, active: 1 },
          validate_filter: useCallback(() => !!row?.contract, [row?.contract]),
          watchers: [row?.contract],
          default_limit: 100
        }}
        loadOnFocus
        disabled={!_.get(row, 'contract')}
        required
      />
    </TableCell>
  </>
}

export default React.memo(Row)
