import React, { useMemo } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [companycontract_id] = hooks.useFormState(['companycontract_id'])
  const [companycontract, { isLoading }] = hooks.useModel('companycontract', [companycontract_id], { single: true })

  const companyContractLabel = useMemo(() => {
    if (!!isLoading) return
    return _.join([_.get(companycontract, 'client_id'), _.get(companycontract, 'name')], '-')
  }, [_.get(companycontract, 'client_id'), _.get(companycontract, 'name'), isLoading])

  const mergedProps = {
    companyContractLabel,
    companycontract_id,
    company: _.get(companycontract, 'company')
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} />
  </Loader>
}

export default withContainer
