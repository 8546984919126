import React from 'react'
import { hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { DateField, TextField, ModelAutocompleteField } from '@front/squirtle'

import { TextCell } from '@abra/elements/table/cells/basics/'
import { UserUtils } from '@front/volcanion/utils'

const Row = ({ row }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)
  return <>
    <TableCell size={'small'}>
      <DateField
        name={'createdAt'}
        inputFormat={'DD/MM/YYYY HH:mm'}
        mask={'__/__/____ __:__'}
        disabled
        renderInputProps={{
          inputProps: { style: { textAlign: 'center' } }
        }}
        disableOpenPicker
      />
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={operator_name} />
    </TableCell>
    <TableCell size={'small'} sx={{ width: 1 / 3 }}>
      <ModelAutocompleteField
        name={'customer'}
        model_name='user'
        config={{
          populate: ['customerinfo', 'info'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user.info, designation: user?.customerinfo?.name }, { suffix: user?.info.user_client_id }),
            ...user
          })
        }}
        autocompleteKey={'customerinfo.client_number'}
        multiple={false}
        searchQuery={'='}
        searchMode='search'
        allowNone={false}
        loadOnFocus
        loadOnMount
      />
    </TableCell>
    <TableCell size={'small'}><TextField name={'reason'} /></TableCell>
  </>
}

export default React.memo(Row)
