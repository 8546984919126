import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [group_id] = hooks.useFormState('group_id')

  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const { isMounted } = hooks.useGlobalFormStatus('drivercontract_list')
  const addSection = hooks.useGlobalFormFunction('drivercontract_list', 'addSection')

  useEffect(() => {
    !!addSection && !!group_id && !isManagingRecord && addSection('_create', { group: { group_id } })
  }, [isMounted])

  const mergedProps = {
    isManagingRecord,
    record_id,
    group_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
