import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const mergedProps = {
    record_id,
    isManagingRecord
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
