import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Typography, Box, StepLabel, StepContent } from '@mui/material'

import Order from './elements/Order'
import Payment from './elements/Payment'
import Password from './elements/Password'
import Call from './elements/Call'

const SettingSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('setting.label', { count: 2 })}</Typography>
  </StepLabel>
  <StepContent >
    <Grid container spacing={1} rowSpacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Box sx={{ borderRadius: 1, boxShadow: 6, p: 2 }}>
          <Order />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ borderRadius: 1, boxShadow: 6, p: 2 }}>
          <Payment />
        </Box>
      </Grid>
      <Grid item xs>
        <Box sx={{ borderRadius: 1, boxShadow: 6, p: 2 }}  >
          <Password />
        </Box>
      </Grid>
      <Call />
    </Grid>
  </StepContent>
</>

export default React.memo(SettingSection)
