class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return filter
    }
  }
  static filterToStateHandler(companycontract) {
    return function filterToState(state) {
      return { companycontract }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return {
        companycontract: _.get(state, 'companycontract'),
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler(record_id) {
    return function formToRecord(values, meta, state) {
      return {
        ...values
      }
    }
  }
}

export default Callbacks
