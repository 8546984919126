import React from 'react'
import { I18n } from '@front/volcanion/'
import { OptionUtils } from '@front/volcanion/utils'

import { Receipt } from '@mui/icons-material'

import { Typography, Grid, Box } from '@mui/material'
import { TextField, SingleOptionSelectorField, ModelMenuSelectorField } from '@front/squirtle/'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name={'require_confirmation'}
        label={I18n.t('order.require_confirmation')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid container item xs={3} spacing={1}>
      <Grid item xs>
        <TextField
          name={'schedule_delay'}
          label={I18n.t('companyservice.schedule_delay')}
          type='number'
          normalize={value => _.max([value, 1])}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <Box color='text.label' fontSize='subtitle2.fontSize'> {I18n.t('minute.label.short')}</Box>
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name={'dispatch_priority'}
          label={I18n.t('priority.dispatching.alter')}
          type='number'
          normalize={value => _.min([_.max([value, 1]), 10])}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
          }}
        />
      </Grid>
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'driver_options'}
        label={I18n.t('options.useroption.label', { count: 1 })}
        model_name={'useroption'}
        loadOnMount
        searchMode='search'
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        multiple
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'vehicle_options'}
        label={I18n.t('options.vehicleoption.label', { count: 1 })}
        model_name={'vehicleoption'}
        searchMode='search'
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        loadOnMount
        multiple
      />
    </Grid>
  </Grid >

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'comments.operator'} label={I18n.t('comment.operator')} alwaysInclude />
    </Grid>
    <Grid item xs>
      <TextField name={'comments.driver'} label={I18n.t('comment.driver')} alwaysInclude />
    </Grid>
  </Grid >

const Order = props =>
  <Grid container spacing={1} rowSpacing={2}>
    <Grid item xs={12}>
      <Box display='flex' alignItems='center'>
        <Box color='primary.main'> <Receipt /> </Box>
        <Typography sx={{ ml: 1, fontSize: 'subtitle.fontSize', color: 'primary.main' }}> {I18n.t('order.label.alter')}: </Typography>
      </Box>
    </Grid>
    <Row1 />
    <Row2 />
  </Grid>

export default React.memo(Order)
