import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'
import { OptionUtils } from '@front/volcanion/utils'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { ModelMenuSelectorField, ModelAutocompleteField } from '@front/squirtle'

const Row1 = ({ categories }) =>
  <Grid container item xs={12} spacing={1}>
    {_.map(categories, (category, key) =>
      <Grid item xs key={key}>
        <ModelMenuSelectorField
          label={_.get(category, 'name')}
          name={`options.${category.id}`}
          model_name='useroption'
          loadOnFocus
          searchMode='search'
          multiple
          config={{
            forced_filter: { categoryoption: category?.id },
            validate_filter: useCallback(() => !!category?.id, [category?.id]),
            watchers: [category?.id],
            mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
          }}
        />
      </Grid>)}
  </Grid>

const Row2 = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'payment_types'}
        label={I18n.t('bank.payment_methods')}
        model_name={'paymenttype'}
        searchMode={'search'}
        config={{ sort: ['display_index ASC'], forced_filter: { disabled: false } }}
        labelKeys={['name_translated']}
        multiple
        loadOnMount
        loadOnFocus
      />
    </Grid>
  </Grid>

const OptionsSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('options.label', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(OptionsSection)
