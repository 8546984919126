import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id, ...props }) => {
  const locale = hooks.useLocale()
  const formProps = {
    name: 'equipment_form',
    model_name: 'equipment',
    record_id,
    related_states: [],
    related_searches: [],
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(locale), [locale]),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    populate: ['name_trkey', 'description_trkey'],
    initialState: { isReadOnly: !!record_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    modifyChangesOnly: false
  }
  const mergedProps = {}
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
