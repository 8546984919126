
import Aux from './Aux'
import moment from 'moment'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        companyservice_id: _.get(record, 'companyservice_id'),
        client_id: _.get(record, 'client_id'),
        name: _.get(record, 'name'),
        status: Aux.loadStatus(record),
        require_confirmation: _.get(record, 'require_confirmation'),
        customer_ref_format: _.get(record, 'customer_ref_format'),
        schedule_delay: _.get(record, 'schedule_delay'),
        dispatch_priority: _.get(record, 'dispatch_priority'),
        can_call_booker: _.get(record, 'can_call_booker'),
        can_call_driver: _.get(record, 'can_call_driver'),
        password: _.get(record, 'password'),
        new_password: _.get(record, 'new_password'),
        new_password_availableSince: _.get(record, 'new_password_availableSince'),
        require_paymentkey: _.get(record, 'require_paymentkey'),
        deferred_billing: _.get(record, 'deferred_billing'),
        driver_options: _.map(_.get(record, 'driver_options'), opt => opt.user_option_id),
        vehicle_options: _.map(_.get(record, 'vehicle_options'), opt => opt.vehicle_option_id),
        customerref_option: {
          fill_ref_rule: _.get(record, 'customerref_option.fill_ref_rule'),
          imposed_ref: !_.get(record, 'customerref_option.manual_allowed'),
          format: _.get(record, 'customerref_option.format'),
          refs: _.get(record, 'customerref_option.refs')
        },
        fill_doc_rule: record?.fill_doc_rule,
        comments: {
          driver: _.get(_.find(_.get(record, 'comments'), comment => _.get(comment, 'type.client_id') === 'service_driver'), 'text'),
          operator: _.get(_.find(_.get(record, 'comments'), comment => _.get(comment, 'type.client_id') === 'service_operator'), 'text'),
          information: _.get(_.find(_.get(record, 'comments'), comment => _.get(comment, 'type.client_id') === 'service_information'), 'text')
        },
        contact: {
          email: _.get(record, 'contact.email'),
          gsm: _.get(record, 'contact.gsm'),
        },
        availableUntil: _.get(record, 'availableUntil'),
        availableSince: _.get(record, 'availableSince'),
        exclude_heatmap: record?.exclude_heatmap,
        hide_driverinfo: record?.hide_driverinfo
      }
    }
  }

  static formToRecordHandler(companycontract_id, commentB2BCategories, commentTypes, operator) {
    return function formToRecord(values, extra, meta, state) {
      return {
        contract: companycontract_id,
        companyservice_id: _.get(values, 'companyservice_id'),
        client_id: _.get(values, 'client_id'),
        name: _.get(values, 'name'),
        status: Aux.loadStatus(values),
        require_confirmation: _.get(values, 'require_confirmation'),
        customer_ref_format: _.get(values, 'customer_ref_format'),
        schedule_delay: _.get(values, 'schedule_delay'),
        dispatch_priority: _.get(values, 'dispatch_priority'),
        can_call_booker: _.get(values, 'can_call_booker'),
        can_call_driver: _.get(values, 'can_call_driver'),
        password: _.get(values, 'password'),
        new_password: _.get(values, 'new_password'),
        new_password_availableSince: _.get(values, 'new_password_availableSince'),
        require_paymentkey: _.get(values, 'require_paymentkey'),
        deferred_billing: _.get(values, 'deferred_billing'),
        driver_options: _.get(values, 'driver_options'),
        vehicle_options: _.get(values, 'vehicle_options'),
        customerref_option: {
          format: _.get(values, 'customerref_option.billing_format'),
          refs: _.get(values, 'customerref_option.refs'),
          fill_ref_rule: _.get(values, 'customerref_option.fill_ref_rule'),
          manual_allowed: _.has(values, 'customerref_option.imposed_ref') ? !_.get(values, 'customerref_option.imposed_ref') : undefined
        },
        fill_doc_rule: values?.fill_doc_rule,
        comments: Aux.exportComments(_.get(values, 'comments'), commentB2BCategories, commentTypes, _.get(operator, 'user_id')),
        contact: {
          email: _.get(values, 'contact.email'),
          gsm: _.get(values, 'contact.gsm'),
        },
        ...Aux.exportStatus(_.get(values, 'status')),
        availableUntil: Aux.exportDate(_.get(values, 'availableUntil'), { hour: 0, minute: 0, second: 0 }),
        availableSince: Aux.exportDate(_.get(values, 'availableSince'), { hour: 23, minute: 59, second: 59 }),
        exclude_heatmap: values?.exclude_heatmap,
        hide_driverinfo: values?.hide_driverinfo
      }
    }
  }

  static getEmptyFormHandler(auth_account) {
    return function getEmptyFormValues() {
      return {
        require_paymentkey: false,
        availableSince: moment.utc().format(),
        can_call_booker: true,
        can_call_driver: true,
        customerref_option: {
          imposed_ref: false,
          fill_ref_rule: 'optional'
        },
        fill_doc_rule: 'optional'
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        companycontract_id: _.get(record, 'contract.companycontract_id'),
        title: _.get(record, 'name'),
        auth_account: _.get(record, 'contract.auth_account'),
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
