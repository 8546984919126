import React from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => props => {
  const [isReadOnly] = hooks.useFormState(['isReadOnly'])
  const [companycontract_id] = hooks.useFormState(['companycontract_id'])
  const [auth_account] = hooks.useFormState('auth_account')
  const [deferred_billing] = hooks.useFieldValues(['deferred_billing'])
  const deferred_billing_options = !auth_account
    ? _.uniqBy([{ label: I18n.t('action.forbidden'), value: 'forbidden' }, { label: I18n.t(`action.${deferred_billing}`), value: deferred_billing }], 'value')
    : [
      { label: I18n.t('action.forbidden'), value: 'forbidden' },
      { label: I18n.t('action.allowed'), value: 'allowed' },
      { label: I18n.t('action.mandatory'), value: 'mandatory' }
    ]
  const fill_ref_rule_options = [
    { label: I18n.t('ref.options.optional'), value: 'optional' },
    { label: I18n.t('ref.options.suggested'), value: 'suggested' },
    { label: I18n.t('ref.options.required'), value: 'required' }
  ]

  const fill_doc_rule_optons = [
    { label: I18n.t('companyservice.fill_doc_rule.options.optional'), value: 'optional' },
    { label: I18n.t('companyservice.fill_doc_rule.options.mandatory'), value: 'mandatory' }
  ]

  const mergedProps = {
    isReadOnly,
    companycontract_id,
    auth_account,
    deferred_billing_options,
    fill_ref_rule_options,
    fill_doc_rule_optons
  }

  return <Component {...mergedProps} />
}
export default withContainer
