class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return filter
    }
  }
  static filterToStateHandler(companycontract) {
    return function filterToState(state) {
      return {
        companycontract,
        pricerule: { '!': null },
      }
    }
  }
  static formToFilterHandler(companycontract_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        company_contracts: companycontract_id,
      }
    }
  }
}

export default Callbacks
