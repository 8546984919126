import { I18n } from '@front/volcanion'
import errors from '@front/volcanion/classes/error'
class Callbacks {
  static formToFilterHandler(record_id) {
    return function formToFilter(record) {
      return {
        user: record_id
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }
  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      if (extra?.data?.result_type === 'success') {
        return {
          user: extra?.data?.result?.user_id,
          ...values,
          group: _.has(values, 'group.group_id') ? _.get(values, 'group.group_id') : undefined
        }
      } else throw extra?.data?.result
    }
  }

  static onSubmitFailedHandler(openNotification) {
    return async function onSubmitFailed(err, values, extra, meta) {
      if (err instanceof errors.MismatchError && err.getCode() === 'DRIVER_CONTRACT_LIMIT') {
        openNotification(I18n.t('driver.driver_contract.error.driver_contract_limit', { driver_contract_limit: err.context?.driver_contract_limit }), { variant: 'error' })
      }
    }
  }

}

export default Callbacks
