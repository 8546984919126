import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Typography, StepLabel, StepContent } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

const Title = props =>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('date.period')} </Typography>
  </StepLabel>

const ServiceSection = ({ record_id, navigate }) => {

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])
  const onRowClick = useCallback((rowId, row) => navigate(`/schedule/${_.get(row, 'schedule.schedule_id')}`), [navigate])

  return (
    <>
      <Title />
      <StepContent >
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <SearchFrame
            model_name={'companyserviceschedules'}
            populate={['schedule']}
            allowCreate={false}
            createPlacement={'bottom'}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              filterToState={filterToState}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                recordToForm,
                formToRecord,
                submitChangesOnly: false
              }}
              tableProps={{
                onRowClick: onRowClick,
                enableToolbar: false,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions getEmptyForm={getEmptyForm}><ButtonGlobalAction /></TableActions>
            </SearchTable>
          </SearchFrame >
        </Box>
      </StepContent >
    </ >
  )
}

export default React.memo(ServiceSection)
