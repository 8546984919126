class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler(group) {
    return function formToFilter(values, extra, meta, state) {
      return {
        group,
        ...values
      }
    }
  }

  static getEmptyFormHandler(has_primary) {
    return function getEmptyFormValues() {
      return {
        is_primary: !has_primary
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler(group) {
    return function formToRecord(values, extra, meta) {
      return {
        group,
        contactinfo: {
          name: _.get(values, 'contactinfo.name'),
          first_name: _.get(values, 'contactinfo.first_name'),
          type: _.get(values, 'contactinfo.type'),
          email: _.get(values, 'contactinfo.email'),
          gsm: _.get(values, 'contactinfo.gsm'),
          tel: _.get(values, 'contactinfo.tel')
        },
        is_primary: values?.is_primary
      }
    }
  }
}

export default Callbacks
