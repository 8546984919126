import React, { useMemo, useEffect } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [payments_types, , { isLoading }] = hooks.useModelSearch('paymenttype', 'get', {
    initial_filter: { disabled: false }
  })

  const [category_options] = hooks.useModelSearch('categoryoption', 'get', {
    initial_filter: { type: 'driver' }
  })

  const categories = useMemo(() => _.map(category_options, (category_option) => ({
    id: _.get(category_option, 'category_option_id'),
    name: _.get(category_option, 'name')
  })), [category_options])

  const mergedProps = {
    categories
  }

  useEffect(() => {
    !isLoading && !_.isEmpty(payments_types) && !isManagingRecord && setFormValues([
      { field: 'payment_types', value: _.map(payments_types, 'paymenttype_id'), meta: { dirty: true } }
    ])
  }, [isLoading])

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
