
import React from 'react'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import GroupTab from '@abra/panels/Tab/Group'

import PrimarySection from './Section/Primary'
import ContactSection from './Section/Contact'
import FinnancialSection from './Section/Finnancial'
import HistorySection from './Section/History'

const Group = ({ isManagingRecord, ...props }) =>
  <Grid container item justifyContent='center' xs={9} rowSpacing={3}>
    <Grid item xs={12}> <GroupTab /> </Grid>
    <Grid item xs={12}>
      <FormHeader goBackPath={'/adherent'} />
      <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical" getStepProps={index => ({ active: (index !== 3 && index !== 2) || !!isManagingRecord })}>
        <Step> <PrimarySection /> </Step>
        <Step> <FinnancialSection /> </Step>
        <Step> <ContactSection /> </Step>
        <Step> <HistorySection /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(Group)
