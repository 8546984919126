import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const mergedProps = {
    isManagingRecord,
    record_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
