import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ record_id, ...props }) => {
  const [createAction] = hooks.useModelFunction('action', 'generate')
  const onSumbitAction = useCallback(async (values, extra, meta, state) => {
    const { rubric, infos } = values
    try {
      await createAction({ data: { driver: record_id, infos, rubric, type: 'driver' } })
    } catch (err) {
      console.warn('Error createAction: ', err)
    }
  }, [record_id, createAction])


  const mergedProps = {
    onSumbitAction
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
