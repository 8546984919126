import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.label.required')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.first_name', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('type.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('phone.gsm')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('email.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('phone.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('address.label', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
