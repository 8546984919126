import React from 'react'

import { I18n } from '@front/volcanion'
import { TextField, DateField, TextPhoneInputField, SingleOptionSelectorField } from '@front/squirtle'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

const Blck1Row1 = props =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <DateField
        name={'info.birth_date'}
        label={I18n.t('driver.birth_date')}
        inputFormat={'DD/MM/YYYY'}
        disableOpenPicker
        required
      />
    </Grid>
    <Grid item xs>
      <TextField name='info.birth_place' label={I18n.t('driver.birth_place')} />
    </Grid>
    <Grid item xs>
      <TextField name='info.nationality' label={I18n.t('driver.nationality')} />
    </Grid>
  </Grid>

const Blck1Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name='driverinfo.driver_license'
        label={I18n.t('driver.driver_license')}
        _adornment={{ sx: { color: 'text.label', mb: '3px' } }}
        InputProps={{ startAdornment: I18n.t('id.number.maj') }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='driverinfo.id_card'
        label={I18n.t('driver.identity_card')}
        _adornment={{ sx: { color: 'text.label', mb: '3px' } }}
        InputProps={{ startAdornment: I18n.t('id.number.maj') }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='driverinfo.capacity_certif'
        label={I18n.t('driver.capacity_certif')}
        _adornment={{ sx: { color: 'text.label', mb: '3px' } }}
        InputProps={{ startAdornment: I18n.t('id.number.maj') }}
      />
    </Grid >
  </Grid>


const Blck2Row1 = props =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <TextField name='info.address' label={I18n.t('address.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='auth.email' label={I18n.t('email.label', { count: 1 })} type='email' required />
    </Grid>
  </Grid>

const Blck2Row2 = ({ defaultCountry, verifyGsm }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextPhoneInputField
        name='auth.gsm'
        label={I18n.t('phone.gsm')}
        defaultCountry={defaultCountry}
        debounceTime={25}
        afterUserChange={verifyGsm}
        required
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'auth.gsm_verified'}
        label={I18n.t('phone.gsm_verified')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <TextField name='info.tel' label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
  </Grid >

const SecondarySection = ({ defaultCountry, verifyGsm }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.utils')} </Typography>
  </StepLabel>
  <StepContent >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Grid container rowSpacing={1}>
            <Blck1Row1 />
            <Blck1Row2 />
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Grid container rowSpacing={1}>
            <Blck2Row1 />
            <Blck2Row2 defaultCountry={defaultCountry} verifyGsm={verifyGsm} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(SecondarySection)
