class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
        contract
      }
    }
  }
  static formToFilterHandler(record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        companyuser: record_id
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        service: _.get(record, 'companyservice.companyservice_id'),
        contract: _.get(record, 'companyservice.contract.companycontract_id'),
        ...record,
      }
    }
  }

  static formToRecordHandler(companyuser) {
    return function formToRecord(values, extra, meta) {
      if (extra?.data?.result_type === 'success') {
        return {
          companyuser: extra?.data?.result?.companyuser_id || companyuser,
          companyservice: _.has(values, 'service') ? _.get(values, 'service') : undefined
        }
      } else throw extra?.data?.result
    }
  }
}

export default Callbacks
