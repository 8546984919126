import React from 'react'

import { OrderLink } from '@abra/elements'
import { TableCell } from '@mui/material'
import { TimeUtils } from '@front/volcanion/utils'
import { TextCell } from '@abra/elements/table/cells/basics/'

import moment from 'moment'

const Row = ({ row }) => {
  const order = row?.first_step?.order
  const isB2B = !!order?.service
  const requestedAt = moment.utc(TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source)).local().format('DD/MM/YYYY HH:mm')
  return (
    <>
      <TableCell size='small' padding='none'>
        <OrderLink record_id={order?.order_id} fontSize={13} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={requestedAt} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? order?.service?.contract?.formula.name : order?.do?.commercial_formula?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? order?.service?.contract?.company?.name : order?.do?.customerinfo?.name} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
