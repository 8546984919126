import { I18n } from '@front/volcanion'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name_trkey: _.get(record, 'name_trkey'),
        description_trkey: _.get(record, 'description_trkey'),
        price: _.get(record, 'price'),
        currency: _.get(record, 'currency')
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        name_trkey: _.merge(_.get(values, 'name_trkey'), { theme: 'option' }),
        description_trkey: _.merge(_.get(values, 'description_trkey'), { theme: 'option' }),
        price: _.get(values, 'price'),
        currency: _.get(values, 'currency'),
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        currency: I18n.t('fiat.eur.sign')
      }
    }
  }

  static recordToStateHandler(locale) {
    return function recordToState(record) {
      return {
        title: _.get(record, `name_trkey.${locale}`)
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
