import React from 'react'

import { Grid, Step, } from '@mui/material'
import { GridStepper } from '@front/squirtle'
import FormHeader from '@abra/elements/headers/Form'

import { PrimarySection, PeriodSection, TargetSection, SettingSection } from './Section'

const CodePromo = ({ isManagingRecord, ...props }) =>
  <Grid container item xs={9} justifyContent='center' rowSpacing={3}>
    <Grid item xs={12}>
      <FormHeader goBackPath={'/codepromo'} />
      <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical">
        <Step> <PrimarySection /> </Step>
        <Step> <PeriodSection /> </Step>
        <Step> <TargetSection /> </Step>
        <Step> <SettingSection /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(CodePromo)
