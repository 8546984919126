import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { DateTimeComponent, TextComponent, TextField } from '@front/squirtle'

const Row1 = ({ isManagingRecord, createdBy, createdAt }) =>
  <Grid container spacing={1}>
    <Grid item xs={4}>
      <TextField
        name='name'
        label={I18n.t('name.label', { count: 1 })}
        required
      />
    </Grid>
    <Grid item xs hidden={!isManagingRecord}>
      <TextComponent
        label={I18n.t('createdBy')}
        value={createdBy}
        disabled
      />
    </Grid>
    <Grid item xs hidden={!isManagingRecord}>
      <DateTimeComponent
        label={I18n.t('date.create')}
        value={createdAt}
        disabled
      /></Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel><Typography variant='h5'>{I18n.t('info.general')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <Row1 {...props} />
        </Box>
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(PrimarySection)
