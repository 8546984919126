import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'
import FormatUtils from '@front/squirtle/utils/format'

const Row1 = ({ supported_languages, verifyClientId }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'group_client_id'}
        label={I18n.t('group.id')}
        debounceTime={250}
        afterUserChange={verifyClientId}
        required
      />
    </Grid>
    <Grid item xs={1}>
      <SingleOptionSelectorField
        name={'member_status'}
        label={I18n.t('status.active')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'name'}
        label={I18n.t('name.label', { count: 1 })}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'company_type'}
        label={I18n.t('company.type')}
        emptyValue={'-'}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'family'}
        label={I18n.t('family')}
        emptyValue={'-'}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'registration_number'}
        label={I18n.t('bank.registration_number')}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'tva_intracommunity_number'}
        label={I18n.t('bank.tva_intracommunity_number')}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs={1}>
      <MenuSelectorField
        name={'code_language'}
        label={I18n.t('lang', { count: 1 })}
        options={_.map(supported_languages, lang => ({
          label: <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ mr: 1 }} > {FormatUtils.getFlagIcon(lang)} </Box>
            <Box> {_.capitalize(lang)} </Box>
          </Box>,
          value: lang,
        }))}
        selectFirst
        validate={isRequired}
        required
      />
    </Grid>
  </Grid >


const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'address'}
        label={I18n.t('address.label', { count: 1 })}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='note'
        label={I18n.t('comment.label', { count: 1 })}
        emptyValue={'-'}
      />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.general')} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
      <Grid container rowSpacing={2} >
        <Row1 {...props} />
        <Row2 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
