import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [{ isManagingRecord, record_id, createdAt }] = hooks.useFormState()
  const [codepromo_user] = hooks.useModel('codepromo', [record_id], { single: true })
  const [{ name: createdBy }] = hooks.useCustomerInfo(codepromo_user?.createdBy)

  const mergedProps = {
    isManagingRecord,
    createdBy,
    createdAt,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
