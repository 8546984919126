import React from 'react'
import { I18n } from '@front/volcanion'

import { TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'
import { TableCell } from '@mui/material'

const inputProps = { style: { textAlign: 'center' } }

const Row = ({ row, afterUserChange, ...props }) => {
  return (
    <>
      <TableCell size={'small'}><TextField name='contactinfo.name' required inputProps={inputProps} /></TableCell>
      <TableCell size={'small'}><TextField name='contactinfo.first_name' inputProps={inputProps} /></TableCell>
      <TableCell size={'small'}>
        <MenuSelectorField
          name='contactinfo.type'
          options={[
            { label: I18n.t('contact.type.director'), value: 'director' },
            { label: I18n.t('contact.type.finance'), value: 'finance' },
            { label: I18n.t('contact.type.marketing'), value: 'marketing' },
            { label: I18n.t('contact.type.sales'), value: 'sales' },
            { label: I18n.t('contact.type.billing'), value: 'billing' }
          ]}
          selectFirst
          required
          inputProps={inputProps}
        />
      </TableCell>
      <TableCell size={'small'}><TextField name='contactinfo.tel' inputProps={inputProps} /></TableCell>
      <TableCell size={'small'}><TextField name='contactinfo.gsm' inputProps={inputProps} /></TableCell>
      <TableCell size={'small'}><TextField name='contactinfo.email' inputProps={inputProps} /></TableCell>
      <TableCell size={'small'}><SingleOptionSelectorField name='is_primary' inputProps={inputProps} afterUserChange={afterUserChange} /></TableCell>
    </>)
}



export default React.memo(Row)
