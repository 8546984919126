import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'
import FormatUtils from '@front/squirtle/utils/format'

const Row1 = ({ verifyClientId }) =>
  <Grid item container xs={12} spacing={1}>
    <Grid item xs={2.4}>
      <TextField name={'client_id'} label={I18n.t('company.id')} debounceTime={250} afterUserChange={verifyClientId} required />
    </Grid>
    <Grid item xs={2.4}>
      <TextField name={'info.nace'} label={I18n.t('company.nace')} required />
    </Grid>
    <Grid item xs={2.4}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={2.4}>
      <TextField name={'contact.gsm'} label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs={2.4}>
      <TextField name={'contact.email'} label={I18n.t('email.label', { count: 1 })} />
    </Grid>
  </Grid>

const Row2 = ({ SUPPORTED_LANGUAGES }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={2.4}>
      <MenuSelectorField
        name={'info.language'}
        label={I18n.t('lang', { count: 1 })}
        options={_.map(SUPPORTED_LANGUAGES, lang => ({
          label: <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ mr: 1 }} > {FormatUtils.getFlagIcon(lang)} </Box>
            <Box> {_.capitalize(lang)} </Box>
          </Box>,
          value: lang,
        }))}
        selectFirst
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs={4.8}>
      <TextField
        name={'contact.address'}
        label={I18n.t('address.label', { count: 1 })}
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs={2.4}>
      <SingleOptionSelectorField
        name={'exclude_heatmap'}
        label={I18n.t('heatmap.exclude_heatmap')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs={2.4}>
      <SingleOptionSelectorField
        name='to_check_billing'
        label={I18n.t('billing.control')}
      />
    </Grid>
  </Grid >

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('company.label', { count: 1 })} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 3 }}>
      <Grid container rowSpacing={2} >
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
