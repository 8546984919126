import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'><TextHeader label={I18n.t('commercialpackage.label', { count: 1 })} icon={'local_taxi'} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('specificparameter.label', { count: 1 })} icon={'settings'} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('ride.label', { count: 1 })} icon={'settings'} /></TableCell>
</>

export default React.memo(Header)
