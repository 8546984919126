
import React from 'react'

import { Grid, Step, } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import CompanyTab from '@abra/panels/Tab/Company'

import PrimarySection from './Section/Primary'
import ServiceSection from './Section/Service'
import PriceRuleSection from './Section/PriceRule'
import CeilingSection from './Section/Ceiling'
import SpecificParameterSection from './Section/SpecificParameter'
import NoShowSection from './Section/NoShow'
import OrderLimitationSection from '@abra/panels/OrderLimitation'

const CompanyContract = ({ isManagingRecord, B2B_COMPANY_MANAGEMENT, ...props }) =>
  <Grid container sx={{ justifyContent: 'center' }}>
    <Grid item xs={10}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} >
          <CompanyTab isManagingRecord={isManagingRecord} value={2} />
        </Grid>
        <Grid item xs={12} >
          <Grid container >
            <Grid item xs>
              <FormHeader />
            </Grid>
            <Grid item xs={12} >
              <GridStepper
                groupsCount={[7]}
                activeStep={0}
                orientation="vertical"
                getStepProps={index => ({ active: index === 0 || !!isManagingRecord })}
              >
                <Step> <PrimarySection /> </Step>
                <Step> <ServiceSection disabled={!isManagingRecord} /> </Step>
                <Step> <OrderLimitationSection model='companycontractorderlimit' primary_key='companycontract_orderlimit_id' /> </Step>
                {!!B2B_COMPANY_MANAGEMENT && <Step> <PriceRuleSection disabled={!isManagingRecord} /> </Step>}
                {!!B2B_COMPANY_MANAGEMENT && <Step> <CeilingSection disabled={!isManagingRecord} /> </Step>}
                {!!B2B_COMPANY_MANAGEMENT && <Step> <SpecificParameterSection disabled={!isManagingRecord} /> </Step>}
                {!!B2B_COMPANY_MANAGEMENT && <Step> <NoShowSection disabled={!isManagingRecord} /> </Step>}
              </GridStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid >

export default React.memo(CompanyContract)
