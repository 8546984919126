import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid } from '@mui/material'

import { FormDialog, DialogContent, TableDialogAction, TextField, ModelMenuSelectorField } from '@front/squirtle/'

const AddAction = props => <>
  <Grid container justifyContent='center' rowSpacing={3}>
    <Grid item xs={8}>
      <ModelMenuSelectorField
        label={I18n.t('rubric', { count: 2 })}
        name={'rubric'}
        model_name='rubric'
        labelKeys={['name_translated']}
        searchMode='search'
        allowNone={false}
        required
        loadOnMount
        autoFocus
      />
    </Grid>
    <Grid item xs={10}>
      <TextField
        label={I18n.t('extra', { count: 1 })}
        required
        name='infos'
        minRows={2}
        multiline
      />
    </Grid>
  </Grid>
</>

const ButtonComponent = ({ onSumbitAction, ...props }) => <>
  <FormDialog
    name={'input_form'}
    formProps={{ onSubmit: onSumbitAction }} >
    <DialogContent>
      <AddAction />
    </DialogContent>
  </FormDialog>

  <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, mt: 2 }}>
    <TableDialogAction
      name={'input_form'}
      label={I18n.t('action.add_free')}
      getDialogExtra={({ row }) => ({ title: I18n.t('action.title_free') })}
    />
  </Box >
</>

export default React.memo(ButtonComponent)
