import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, Icon } from '@mui/material'
import { SingleOptionSelectorField } from '@front/squirtle/'

const Title = props =>
  <Grid item xs={12}>
    <Box display='flex' alignItems='center'>
      <Icon> {'call'} </Icon>
      <Typography sx={{ ml: 1, fontSize: 'subtitle.fontSize', color: 'primary.main' }}> {I18n.t('call.label', { count: 1 })}: </Typography>
    </Box>
  </Grid>

const Row1 = ({ DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={!DRIVER_CAN_CALL_CLIENT}>
      <SingleOptionSelectorField name={'can_call_booker'} optionLabel={I18n.t('call.driver_client')} />
    </Grid>
    <Grid item xs hidden={!CLIENT_CAN_CALL_DRIVER}>
      <SingleOptionSelectorField name={'can_call_driver'} optionLabel={I18n.t('call.client_driver')} />
    </Grid>
  </Grid >

const Call = ({ DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER }) =>
  <Grid item xs={4} hidden={!DRIVER_CAN_CALL_CLIENT && !CLIENT_CAN_CALL_DRIVER}>
    <Box sx={{ borderRadius: 1, boxShadow: 6, p: 2 }} >
      <Grid container spacing={1} rowSpacing={2}>
        <Title />
        <Row1 DRIVER_CAN_CALL_CLIENT={DRIVER_CAN_CALL_CLIENT} CLIENT_CAN_CALL_DRIVER={CLIENT_CAN_CALL_DRIVER} />
      </Grid>
    </Box>
  </Grid>

export default React.memo(Call)

