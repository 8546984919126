import React from 'react'
import { I18n } from '@front/volcanion'

import { Box } from '@mui/material'

import { AddRowAction } from '@front/squirtle/components/FormTable/Actions'

const ButtonComponent = props => {
  return (<>
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, mt: 2 }}>
      <AddRowAction
        label={I18n.t('contact.add', { count: 1 })}
        labelProps={{ fontSize: 15, mt: '1px', fontWeight: 600, ml: 1 }}
        size={'small'}
        variant={'outlined'}
        sx={{ textTransform: 'none' }}
        getEmptyForm={props?.getEmptyForm}
      />
    </Box >
  </>
  )
}

export default React.memo(ButtonComponent)
