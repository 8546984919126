import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [supported_languages] = hooks.useSetting('supported_languages')
  const verifyClientId = useCallback(hooks.useRecordExist('group', 'group_client_id'), [])

  const mergedProps = {
    supported_languages,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
