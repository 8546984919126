class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {
        ...state
      }
    }
  }
  static formToFilterHandler(record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        service: record_id
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler(record_id) {
    return function formToRecord(values, extra, meta) {
      return {
        reason: _.get(values, 'reason'),
        operator: _.get(values, 'operator'),
        driver: _.get(values, 'driver'),
        service: record_id
      }
    }
  }
}

export default Callbacks
