import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable } from '@front/squirtle'

import Callbacks from './callbacks'

import Form from './Form'
import Header from '../../Service/List/Header'
import Row from '../../Service/List/Row'

const ServiceSection = ({ companycontract_id, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(companycontract_id), [companycontract_id])

  const handleRowClick = useCallback((rowId, row) => window.open(`/companyservice/${rowId}`), [])
  const handleCreate = useCallback(() =>
    window.open(`/companyservice/create?companycontract_id=${companycontract_id}`, '_blank')
    , [companycontract_id])

  return (
    <SearchFrame
      model_name={'companyservice'}
      populate={['contact']}
      createButtonProps={{
        label: I18n.t('companyservice.add', { count: 1 }),
        onClick: handleCreate
      }}
    >
      <SearchForm
        submitOnMount
        getButtonStyleProps={() => ({ justifyContent: 'flex-start' })}
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <Form companycontract_id={companycontract_id} />
      </SearchForm>
      <SearchTable tableProps={{
        onRowClick: handleRowClick
      }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  )
}

export default React.memo(ServiceSection)
