import React from 'react'

import { TableCell } from '@mui/material'

import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'commercial_package.name_translated')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.start_zone')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.end_zone')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.max_rate_customer')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.min_rate_customer')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.max_rate_driver')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'ceiling.min_rate_driver')} /></TableCell>
</>

export default React.memo(Row)
