import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')

  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const B2B_COMPANY_MANAGEMENT = hooks.useRelayFeature('B2B_COMPANY_MANAGEMENT')

  const mergedProps = {
    B2B_COMPANY_MANAGEMENT,
    isManagingRecord
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
