import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextField, MenuSelectorField } from '@front/squirtle'

const inputProps = { style: { textAlign: 'center' } }

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextField name={'contact.last_name'} inputProps={inputProps} /></TableCell>
  <TableCell size={'small'}><TextField name={'contact.first_name'} inputProps={inputProps} /></TableCell>
  <TableCell size={'small'}>
    <MenuSelectorField
      name={'contact.type'}
      options={[
        { label: I18n.t('contact.type.director'), value: 'director' },
        { label: I18n.t('contact.type.finance'), value: 'finance' },
        { label: I18n.t('contact.type.marketing'), value: 'marketing' },
        { label: I18n.t('contact.type.sales'), value: 'sales' },
        { label: I18n.t('contact.type.billing'), value: 'billing' }
      ]}
      selectFirst
      required
    />
  </TableCell>
  <TableCell size={'small'}><TextField name={'contact.gsm'} inputProps={inputProps} /></TableCell>
  <TableCell size={'small'}><TextField name={'contact.email'} inputProps={inputProps} /></TableCell>
  <TableCell size={'small'}><TextField name={'contact.tel'} inputProps={inputProps} /></TableCell>
  <TableCell size={'small'}><TextField name={'contact.address'} inputProps={inputProps} /></TableCell>
</>


export default React.memo(Row)
