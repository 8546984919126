import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [{ record_id }] = hooks.useFormState()


  const mergedProps = {
    isManagingRecord,
    record_id
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} />
  </Loader>
}

export default withContainer
